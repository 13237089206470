<template>
  <div class="common-card">
    <el-card shadow="never" :body-style="{ padding: '0px' }" class="box-card">
      <!-- 头部 -->
      <template #header>
        <div class="card-header">
          <span>{{ headerTitle }}</span>
        </div>
      </template>
      <div class="main">
        <slot></slot>
        <!-- <div v-if="!flagTag">以下步骤将知道你上传项目。提交后，你可以通过yyHome网址查看和编辑您的项目。</div>
        <div v-else>你的项目将获得唯一的标识符：BP00001,,若之后的流程中您会批量上传数据，则回味每个数据赋予与之对应的唯一标识符，请在和yyHome团队的任何沟通中引用此标记</div> -->
      </div>
      <!-- 底部 -->
      <div class="footer" v-if="showBtn">
        <el-button v-if="showBack" @click="$router.back()">{{$t('upload.Step.cancel')}}</el-button>
        <el-button type="primary" @click="handleClick">
          {{ footerTitle }}
        </el-button>
      </div>
    </el-card>
  </div>
</template>

<script setup lang="ts">
import { ref, withDefaults } from 'vue'

// defineProps<{
//   headerTitle: string //头部标题
//   footerTitle: string //button标题
//   isLoadButton?: boolean //控制button是否开启loading
// }>()

withDefaults(
  defineProps<{
    headerTitle: string //头部标题
    footerTitle: string //button标题
    showBack?: boolean //是否显示取消按钮
    isLoadButton?: boolean //控制button是否开启loading
    showBtn?:boolean
  }>(),
  {
    showBack: false,
    showBtn:true
  }
)

const emits = defineEmits<{
  (e: 'click'): void
}>()

// 按钮的点击事件
const loading = ref(false)
const handleClick = () => {
  
  emits('click')
}
</script>

<script lang="ts">
export default {
  name: 'CommonCard',
}
</script>

<style lang="scss" scoped>
.common-card {
  .card-header {
    padding-left: 30px;
    font-size: 20px;
    color: #006bc6;
    font-weight: bold;
  }
  .main {
    padding-top: 20px;
    padding-left: 50px;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
    border-top: 1px solid $borderColorLight;
    padding: 20px 50px;
    /* width: 100%;
    :deep(.el-button) {
      width: 100%;
    } */
    .el-button + .el-button {
      margin-left: 0;
    }
  }
}
</style>
